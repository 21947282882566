import {
  ActionStatus,
  ActionType,
  Adapter,
  AvailableDebitSchemeEnum,
  BillingRequestFlowResource,
  BillingRequestResource,
  BillingRequestsStatus,
  ConfigSchemeIdentifier,
  HomeSchemeEnum,
  MandateRequestVerify,
  PaylinkResource,
  Currency,
  BillingRequestCollectBankAccountRequestBody,
  BankAuthorisationResource,
} from "@gocardless/api/dashboard/types";
import { HTTPError } from "@gocardless/api/utils/api";
import { HTTPStatusCode } from "@gocardless/api/http/http-status-codes";
import { escapeRegExp, keys, includes, lowerCase } from "lodash";
import {
  BankAuthFailureReasonsToShowFallback,
  BankAuthFailureReasonsEnum,
  ErrorTypeEnum,
} from "src/state/errors";
import { ErrorType, PayerThemeType, ResidenceCountryMetaType } from "src/state";
import { BankGB, BankLogoGB, BankLogoImageNameGB } from "src/common/banks";
import { findAction, Routes } from "src/components/shared/Router";
import router from "next/router";
import { I18n } from "@lingui/core";
import { getPaymentVariant, PaymentVariant } from "src/common/payments";
import { customerDetailsForSchemeAndCountry } from "src/config/customer-details/customer-details-config";
import { Environment, Role } from "src/common/environments";
import { NestDataObject } from "react-hook-form";
// eslint-disable-next-line import/no-unresolved
import { ReactSDKClient } from "@optimizely/react-sdk/dist/client";
import { RuntimeMode } from "src/state/RuntimeModeInitialiser";

import getAppConfig, { isRole } from "./config";
import { Locale, DEFAULT_LOCALE } from "./i18n";
import { BankRecurringSchemes, Scheme } from "./scheme";
import { CountryCodes, countryCodeToName } from "./country";

export interface InputFormatterOptions {
  delimiter?: string | string[];
  parts?: number[];
  maxLength?: number;
  onlyNumbers?: boolean;
}

// title cases and preserves hyphens in between double names (e.g. First Mid-Last)
export const formatNameToTitleCase = (names?: string) =>
  names
    ? names
        .toLowerCase()
        // remove chars that are not names, spaces or hyphens
        .replace(/[^\w\s-]|_/g, "")
        // remove hyphens at the end of words or before spaces
        .replace(/-\s/g, " ")
        // capitalize chars at boundary (space, start of word, or hyphen)
        .replace(/\b\w/g, (l) => l.toUpperCase())
        // remove trailing hyphens
        .replace(/-\s*$/g, "")
        .trim()
    : "";

export const merchantSchemeIdentifier = (
  schemeIdentifiers?: ConfigSchemeIdentifier[],
  scheme?: string | null
): ConfigSchemeIdentifier | undefined => {
  if (!schemeIdentifiers || !scheme) return;

  const value = schemeIdentifiers.find(
    (schemeIdentifier: ConfigSchemeIdentifier) => {
      if (schemeIdentifier.scheme === scheme) {
        return true;
      }
      // this is not expected to happen, because `schemeIdentifier.scheme`
      // won't be `sepa`, but `sepa_core` (the same as `scheme`);
      // the generated type is wrong, the check is here just in case
      if (
        scheme === AvailableDebitSchemeEnum.SepaCore &&
        schemeIdentifier.scheme === HomeSchemeEnum.Sepa
      ) {
        return true;
      }
      return false;
    }
  );

  return value;
};

export const showError = (
  error: HTTPError,
  setError: React.Dispatch<ErrorType>,
  component: string,
  errorTypeIfNotMatched: ErrorTypeEnum = ErrorTypeEnum.GenericError
) => {
  let errorType = null;
  let httpError: undefined | HTTPError = undefined;
  if (error?.response) {
    const { status, statusText, type, url } = error.response;
    httpError = Object.assign(error, {
      responseJSON: JSON.stringify({
        status,
        statusText,
        type,
        url,
      }),
    });
  }

  switch (error?.response?.status) {
    case HTTPStatusCode.NotFound:
    case HTTPStatusCode.Unauthorized:
      errorType = ErrorTypeEnum.LinkExpired;
      break;
    case undefined && error?.name === "TimeoutError":
      errorType = ErrorTypeEnum.ClientTimeout;
      break;
    default:
      errorType = errorTypeIfNotMatched;
  }
  setError({
    errorType: errorType,
    errorMessage: `${component}: ${error?.response?.status} - ${error?.message}`,
    httpError,
  });
};

export const shouldMockRequests = () => {
  return getAppConfig().client.nextPublicAPIMocking;
};

export const isMandateOnlyFlow = (
  billingRequest: BillingRequestResource | undefined
) =>
  Boolean(billingRequest?.mandate_request) && !billingRequest?.payment_request;

export const isDualFlow = (
  billingRequest: BillingRequestResource | undefined
) =>
  Boolean(billingRequest?.mandate_request) &&
  Boolean(billingRequest?.payment_request);

export const isOneOffPaymentFlow = (
  billingRequest: BillingRequestResource | undefined
) =>
  !billingRequest?.mandate_request && Boolean(billingRequest?.payment_request);

export const isUsingCompanyName = (billingRequest?: BillingRequestResource) =>
  !!billingRequest?.resources?.customer?.company_name;

export const getPayerName = (billingRequest: BillingRequestResource) => {
  const isCompany = isUsingCompanyName(billingRequest);
  return isCompany
    ? billingRequest?.resources?.customer?.company_name
    : billingRequest?.resources?.customer?.given_name +
        " " +
        billingRequest?.resources?.customer?.family_name;
};

// Works with GB banks only
export const getBankLogoGB = (
  bankName?: string | null
): BankLogoImageNameGB => {
  let logo = "" as BankLogoImageNameGB;
  if (bankName) {
    const formattedBankName: string = lowerCase(bankName).replace(/[ _]/g, "");

    const bankLogoKeys = keys(BankLogoGB);
    for (const key of bankLogoKeys) {
      const formattedKey = lowerCase(key).replace(/[ _]/g, "");
      if (includes(formattedBankName, formattedKey)) {
        logo = BankLogoGB[key as BankGB];
        break;
      }
    }
  }
  return logo;
};

export const getFormattedInput = (
  value: string,
  options: InputFormatterOptions = {},
  isDeleteEvent = false
): string => {
  const { onlyNumbers = false, maxLength = 0, parts, delimiter = "" } = options;

  let rawValue: string = value;
  let formattedValue = "";

  // clean delimiters before formatting
  (Array.isArray(delimiter) ? delimiter : [delimiter]).forEach((item) => {
    const delimiterRegExp = new RegExp(escapeRegExp(item), "g");
    rawValue = rawValue.replace(delimiterRegExp, "");
  });

  // clean non-numeric characters before formatting
  if (onlyNumbers) {
    rawValue = rawValue.replace(/\D/g, "");
  }

  if (rawValue) {
    // limit by length before formatting
    if (maxLength && rawValue.length > maxLength) {
      rawValue = rawValue.substring(0, maxLength);
    }

    // split on parts
    if (parts?.length) {
      let partStart = 0;
      let partEnd = 0;
      for (let i = 0; i < parts.length; i++) {
        partEnd = partStart + (parts[i] ?? 0);

        formattedValue += rawValue.substring(partStart, partEnd);

        // add delimiter (and remains on the last iteration)
        // if we are expecting for input continuing
        const maxLengthExceeded = maxLength && maxLength <= partEnd;
        if (rawValue.length >= partEnd && !maxLengthExceeded) {
          const isLastIteration: boolean = i + 1 === parts.length;
          const partRemains: string = isLastIteration
            ? rawValue.substring(partEnd)
            : "";
          const partDelimiter: string =
            (Array.isArray(delimiter) ? delimiter[i] : delimiter) ?? "";

          const isPartLastCharacter: boolean =
            partEnd === rawValue.length && isDeleteEvent;

          if (isPartLastCharacter) {
            break;
          }
          formattedValue += partDelimiter + partRemains;
        }
        partStart = partEnd;
      }
      return formattedValue;
    }
  }
  return rawValue;
};

export const formatInput = (
  e: React.FormEvent<HTMLInputElement>,
  formatterOptions?: InputFormatterOptions
): void => {
  if (formatterOptions) {
    const input: HTMLInputElement = e.currentTarget;
    const inputEventType = (e.nativeEvent as InputEvent).inputType;

    const isDeleteEvent = !!inputEventType?.startsWith("delete");
    input.value = getFormattedInput(
      input.value,
      formatterOptions,
      isDeleteEvent
    );
  }
};

/**
 * For parsing flow id or template id from url
 * when integrators pass something like this
 * /billing/static/flow?id=BRF0000000?invalid?null
 *
 * @param queryParameter string
 * @return valid url query parameter
 */
export const getIdFromQueryParameter = (queryParameter?: string | string[]) => {
  if (Boolean(queryParameter) && typeof queryParameter === "string") {
    return queryParameter.replace(/[^A-Za-z0-9].*$/, "");
  }
  return queryParameter;
};

export enum BrandedComponentType {
  Button = "button",
  Link = "link",
  FooterLink = "footerLink",
}

// used the hex literal because the lighten
// and dark functions would not allow an Enum
export const BrandedFooterLinkColor = "#2c2d2f";
export const BrandedFooterDropinModeLinkColor = "#ffffff";

export const getBrandColorFor = (
  componentName: string,
  payerTheme?: PayerThemeType,
  isDropinMode?: boolean
) => {
  if (!payerTheme) {
    return undefined;
  }
  switch (componentName) {
    case BrandedComponentType.Link:
      return payerTheme.link_text_colour;
    case BrandedComponentType.Button:
      return payerTheme.button_background_colour;
    case BrandedComponentType.FooterLink:
      if (isDropinMode) return BrandedFooterDropinModeLinkColor;
      return BrandedFooterLinkColor;
    default:
      return "";
  }
};

export const isSepaIBPScheme = (billingRequest: BillingRequestResource) =>
  billingRequest.payment_request &&
  [
    HomeSchemeEnum.SepaCreditTransfer,
    HomeSchemeEnum.SepaInstantCreditTransfer,
  ].includes(billingRequest.payment_request.scheme as HomeSchemeEnum);

export const isBillingRequestSuccessful = (
  billingRequest?: BillingRequestResource
) =>
  billingRequest?.status &&
  [BillingRequestsStatus.Fulfilling, BillingRequestsStatus.Fulfilled].includes(
    billingRequest.status
  );

export const isPayToFlowScheme = (billingRequest: BillingRequestResource) =>
  billingRequest.mandate_request?.scheme === HomeSchemeEnum.PayTo ||
  billingRequest.payment_request?.scheme === HomeSchemeEnum.PayTo;

/**
 * Returns prioritised scheme for Billing Request for UI
 * Priority:
 *    recurring mandate request scheme  ->
 *    payment request scheme ->
 *    other mandate request schemes
 *
 * @param billingRequest Billing Request object
 * @return prioritised scheme
 */
export const leadBillingRequestScheme = (
  billingRequest?: BillingRequestResource | null
) => {
  const mandate_request_scheme = billingRequest?.mandate_request?.scheme;
  const payment_request_scheme = billingRequest?.payment_request?.scheme;
  if (BankRecurringSchemes.includes(mandate_request_scheme as HomeSchemeEnum)) {
    return mandate_request_scheme;
  }
  return payment_request_scheme || mandate_request_scheme || null;
};

/**
 * Checks if BR is under bank recurring flow
 * The flow is bank recurring when:
 * 1. payment request and/or mandate request has the pay_to
 * 2. mandate request has the faster_payments scheme
 *
 * @param billingRequest Billing Request object
 * @return true for the bank recurring flow
 */
export const isBankRecurringFlow = (
  billingRequest?: BillingRequestResource | null
) => {
  const mandate_request_scheme = billingRequest?.mandate_request
    ?.scheme as HomeSchemeEnum;
  const payment_request_scheme = billingRequest?.payment_request
    ?.scheme as HomeSchemeEnum;
  if (
    BankRecurringSchemes.includes(mandate_request_scheme) ||
    payment_request_scheme === HomeSchemeEnum.PayTo
  ) {
    return true;
  }
  return false;
};

/**
 * Checks if BR is under VRP flow
 *
 * @param billingRequest Billing Request object
 * @return true for VRP
 */
export const isVRPFlow = (billingRequest: BillingRequestResource) =>
  billingRequest.mandate_request?.scheme === HomeSchemeEnum.FasterPayments;

export const isSEPASchemeMandateOnly = (
  billingRequest: BillingRequestResource
) =>
  !billingRequest?.payment_request &&
  billingRequest?.mandate_request?.scheme === AvailableDebitSchemeEnum.SepaCore;

export const isAchMxExperimentMandate = (
  billingRequest?: BillingRequestResource | undefined,
  country_code?: CountryCodes | undefined
) =>
  billingRequest?.experimentation?.is_eligible_for_ach_mx_experiments &&
  country_code === CountryCodes.US;

export const canShowAccountBalance = (
  billingRequest: BillingRequestResource | undefined
) => {
  return (
    isMandateOnlyFlow(billingRequest) &&
    schemeRequiredToShowBalance(
      billingRequest?.mandate_request?.scheme as string
    )
  );
};

export const schemeRequiredToShowBalance = (scheme: string) =>
  [AvailableDebitSchemeEnum.Bacs].includes(scheme as AvailableDebitSchemeEnum);

export const mandateSchemeIs = (
  billingRequest: BillingRequestResource,
  scheme: Scheme
) => {
  const mandateScheme = billingRequest?.mandate_request?.scheme as Scheme;

  return mandateScheme === scheme;
};

export const isVerifiedMandate = (billingRequest: BillingRequestResource) => {
  const verify = billingRequest?.mandate_request
    ?.verify as MandateRequestVerify;
  return [
    MandateRequestVerify.WhenAvailable,
    MandateRequestVerify.Always,
  ].includes(verify);
};

/**
 * Returns Billing Request currency
 * as there is no way to create a Billing Request
 * with different currencies for payment_request and mandate_request
 * it doesn't really matter what to prioritise
 *
 * @param billingRequest Billing Request object
 * @return currency
 */
export const getBillingRequestCurrency = (
  billingRequest: BillingRequestResource
) =>
  billingRequest.payment_request?.currency ??
  billingRequest.mandate_request?.currency;

export const isUKPaymentFlow = (
  billingRequest: BillingRequestResource
): boolean => billingRequest.payment_request?.currency === Currency.Gbp;

export const isEligibleForRemovePayerName = (
  billingRequest: BillingRequestResource
): boolean =>
  billingRequest?.experimentation
    ?.is_eligible_for_remove_payer_name_experiments;

export const isEligibleForNetflixABExperiment = (
  billingRequest: BillingRequestResource
): boolean =>
  billingRequest?.experimentation?.is_eligible_for_netflix_ab_experiments;

// used to grab the user preferred language(s) from their browser
// the navigator.languages returns an array of DOMStrings representing
// the user's preferred languages. The value of navigator.language
// is the first element of the returned array. currently you can add
// a languageCodeOnly option which trims off the country codes of locales
// before it returns them. e.g "en-GB" becomes "en" with the languageCodeOnly
// option set to true.
export const getBrowserLocales = (options = {}) => {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  if (!navigator.language) {
    return undefined;
  }

  const browserLocales: Readonly<Array<string>> = navigator.languages
    ? navigator.languages
    : [navigator.language];

  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
};

// used to deduce the intersection (matching strings) between
// two arrays (a smaller sample and a larger data set).
const findMatch = (sample: string[], data: string[]) => {
  return sample.filter((item) => data.includes(item));
};

// this is used to calculate the best match locale within the user
// preferred list of locales and the Billing Request FLow app
// accepted list of locales (Locale Enum). It is also smart enough
// to match a Language code only locale such as "da" in the user's
// preferred list to it's full language and country code equivalent
// "da-DK" in the Billing Request Flow app accepted list of locales.
// This function returns the DEFAULT_LOCALE ('en-GB') if it's
// unable to find a best match.
export const findBestMatchLocale = () => {
  const acceptedLocale = Object.values(Locale); // e.g ["en-GB", "da-DK"]

  const acceptedLocaleCodeOnly = acceptedLocale.map((locale) => {
    return locale.trim().split(/-|_/)[0];
  }) as string[]; // e.g ["en", "da"]
  const preferredLocaleCodeOnly = getBrowserLocales({
    languageCodeOnly: true,
  }) as string[]; // e.g ["de", "fi"]

  // travel down happy path if the user's preferred list is populated
  if (preferredLocaleCodeOnly) {
    const matches = findMatch(preferredLocaleCodeOnly, acceptedLocaleCodeOnly);

    if (matches.length && matches[0]) {
      const bestMatch = matches[0].toLowerCase();

      const userLocale = acceptedLocale.filter((locale) =>
        locale.toLowerCase().includes(bestMatch)
      )[0];

      if (userLocale) {
        return userLocale;
      }
    }
  }

  // if the user's preferred list of locale can't be grabbed from the browser
  // return the default "en-GB"
  return DEFAULT_LOCALE;
};

// this function checks if the adapter attached to the billing request is
// OpenBankingGatewayAis
export const usesOpenBankingGatewayAisAdapter = (
  billingRequest: BillingRequestResource,
  actionStatus?: ActionStatus
) => {
  // get the bank authorisation action tied to this billing request
  const bankAuthorisationAction = findAction(
    billingRequest,
    ActionType.BankAuthorisation,
    actionStatus || null
  );
  return (
    bankAuthorisationAction?.bank_authorisation?.adapter ===
    Adapter.OpenBankingGatewayAis
  );
};

export const usesBankIdAisAdapter = (
  billingRequest: BillingRequestResource,
  actionStatus?: ActionStatus
) => {
  // get the bank authorisation action tied to this billing request
  const bankAuthorisationAction = findAction(
    billingRequest,
    ActionType.BankAuthorisation,
    actionStatus || null
  );
  return (
    bankAuthorisationAction?.bank_authorisation?.adapter === Adapter.BankidAis
  );
};

// this function checks if the adapter attached to the billing request is
// BankPayRecurring
export const usesBankPayRecurringAdapter = (
  billingRequest: BillingRequestResource,
  actionStatus?: ActionStatus
) => {
  // get the bank authorisation action tied to this billing request
  const bankAuthorisationAction = findAction(
    billingRequest,
    ActionType.BankAuthorisation,
    actionStatus || null
  );
  return (
    bankAuthorisationAction?.bank_authorisation?.adapter ===
    Adapter.BankPayRecurring
  );
};

export const getBankAuthorisationAdapter = (
  billingRequest: BillingRequestResource
) => {
  const bankAuthorisationAction = findAction(
    billingRequest,
    ActionType.BankAuthorisation,
    null
  );
  return bankAuthorisationAction?.bank_authorisation?.adapter;
};

export const retryMandateFlow = async (
  billingRequest?: BillingRequestResource
) => {
  if (!billingRequest) return;

  switch (billingRequest?.mandate_request?.verify) {
    case MandateRequestVerify.WhenAvailable:
    case MandateRequestVerify.Always:
      await router.push({
        pathname: Routes.BankSelect.replace("/", ""),
        query: router.query,
      });
      break;
    default:
      await router.push({
        pathname: Routes.CollectBankAccount.replace("/", ""),
        query: router.query,
      });
      break;
  }
};

export const retryPaymentFlow = async (
  billingRequest?: BillingRequestResource
) => {
  if (!billingRequest) return;

  const isSandbox = isRole(Role.sandbox);
  const clearParameters = ["consent_id", "error", "outcome", "reason"];
  const filteredQueryParams = Object.fromEntries(
    Object.entries(router.query).filter(
      ([key]) => !clearParameters.includes(key)
    )
  );

  if (
    // PayTo does not go through bank select
    isPayToFlowScheme(billingRequest) ||
    // SEPA only goes through bank select in sandbox
    (isSepaIBPScheme(billingRequest) && !isSandbox)
  ) {
    await router.push({
      pathname: Routes.CollectBankAccount.replace("/", ""),
      query: filteredQueryParams,
    });
  } else {
    await router.push({
      pathname: Routes.BankSelect.replace("/", ""),
      query: filteredQueryParams,
    });
  }
};

export const formatCountryCodes = (
  availableCountryCodes: CountryCodes[],
  i18n: I18n
) => {
  return availableCountryCodes
    .filter(
      (availableCountryCode) => availableCountryCode in countryCodeToName(i18n)
    )
    .sort((a, b) =>
      countryCodeToName(i18n)[a].localeCompare(countryCodeToName(i18n)[b])
    );
};

export const isDateInThePast = (date: string): boolean => {
  return new Date(date).getTime() <= new Date().getTime();
};

export const getPaymentDescription = (
  billingRequest: BillingRequestResource,
  paylink?: PaylinkResource
) => {
  const paymentVariant = getPaymentVariant(billingRequest, paylink);
  const isInstantPay =
    paymentVariant === PaymentVariant.InstantBankPay ||
    paymentVariant === PaymentVariant.DualFlow ||
    paymentVariant === PaymentVariant.VariableRecurringPaymentsWithFirstPayment;
  return isInstantPay
    ? billingRequest.payment_request?.description
    : paylink?.name;
};

export const shouldDisplaySelfAuthorisationCheck = (
  billingRequest: BillingRequestResource | undefined,
  billingRequestFlow: BillingRequestFlowResource | undefined,
  optimizely: ReactSDKClient | null
) => {
  const organisationId = billingRequest?.links?.organisation;
  if (organisationId) {
    const optOut =
      optimizely?.decide("brf_dual_sig_opt_out", [], organisationId, {
        organisation_id: organisationId,
      }).variationKey === "on";

    // Organisation is in `opted_out_orgs` audience for `brf_dual_sig_opt_out` flag
    if (optOut) {
      return false;
    }
  }

  const scheme = billingRequest?.mandate_request
    ?.scheme as AvailableDebitSchemeEnum;

  return Boolean(
    [AvailableDebitSchemeEnum.Becs, AvailableDebitSchemeEnum.Bacs].includes(
      scheme
    ) ||
      (scheme === AvailableDebitSchemeEnum.SepaCore &&
        billingRequest &&
        billingRequestFlow &&
        getResidenceCountryMetadata({
          billingRequest,
          billingRequestFlow,
        }).countryCode === CountryCodes.IE)
  );
};

export const shouldDisplayDualSigSuccessPage = (
  billingRequest: BillingRequestResource,
  billingRequestFlow: BillingRequestFlowResource | undefined
) =>
  Boolean(
    billingRequest.mandate_request?.payer_requested_dual_signature &&
      billingRequestFlow?.redirect_uri
  );

export const shouldSkipSuccessScreen = (
  billingRequestFlow: BillingRequestFlowResource | undefined
) => {
  return Boolean(
    billingRequestFlow?.redirect_uri && billingRequestFlow.skip_success_screen
  );
};

export const shouldRedirectToDualSigFlow = (
  billingRequest: BillingRequestResource | undefined,
  billingRequestFlow: BillingRequestFlowResource | undefined
) =>
  Boolean(billingRequest?.sign_flow_url && !billingRequestFlow?.redirect_uri);

export const shouldShowBankAuthorisationEventTriggers = (
  billingRequest: BillingRequestResource,
  page: Routes
) => {
  const env = getAppConfig().shared.environment;

  return (
    env === Environment.staging &&
    page === Routes.BankWait &&
    isPayToFlowScheme(billingRequest)
  );
};

/**
 * Returns Residence Country Metadata object
 * which includes country code and scheme + country customer fields config
 */
export const getResidenceCountryMetadata = ({
  billingRequest,
  billingRequestFlow,
}: {
  billingRequest: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
}): ResidenceCountryMetaType => {
  const scheme = billingRequest.mandate_request?.scheme;
  let countryCode: CountryCodes | undefined = undefined;

  if (billingRequest?.resources?.customer_billing_detail?.country_code) {
    countryCode = billingRequest?.resources?.customer_billing_detail
      ?.country_code as CountryCodes;
  }

  // If we haven't already set one, try and use the country code suggested by
  // the API.
  if (!countryCode && billingRequest.actions) {
    const action = billingRequest.actions.find(
      (a) => a.type === ActionType.CollectCustomerDetails
    );
    if (action) {
      countryCode = action.collect_customer_details
        ?.default_country_code as CountryCodes;
    }
  }

  const prefilledCountry = billingRequestFlow?.prefilled_customer
    ?.country_code as CountryCodes;

  // If prefilled country exists and is valid, set as country code
  // prefilled details
  if (
    billingRequestFlow?.prefilled_customer?.country_code &&
    Object.values(CountryCodes).includes(prefilledCountry as CountryCodes)
  ) {
    countryCode = prefilledCountry;
  }

  // If we know the customer country code we can build fields config object
  if (countryCode && scheme) {
    return {
      countryCode,
      customerFieldsConfig: customerDetailsForSchemeAndCountry(
        scheme,
        countryCode,
        billingRequest
      ),
    };
  }

  return { countryCode };
};

/**
 * Returns True if a billing request action is required
 *
 * For selectInstitution action, we need to make sure that the
 * bank authorisation action does not also require institution
 */
export const requiresAction = ({
  billingRequest,
  actionType,
}: {
  billingRequest: BillingRequestResource;
  actionType: ActionType;
}): boolean | undefined => {
  const action = findAction(billingRequest, actionType, null);
  if (action) {
    if (action.type === ActionType.SelectInstitution) {
      const bankAuthorisationAction = findAction(
        billingRequest,
        ActionType.BankAuthorisation,
        null
      );
      return (
        action.required ||
        bankAuthorisationAction?.requires_actions?.includes(
          "select_institution"
        )
      );
    }
    return action.required;
  }

  return false;
};

/**
 * Returns True if SelectInstitution is required in an
 * instant payment flow
 */
export const requireSelectedInstitution = ({
  billingRequest,
}: {
  billingRequest: BillingRequestResource;
}): boolean | undefined => {
  return (
    Boolean(billingRequest?.payment_request) &&
    !billingRequest?.fallback_occurred &&
    requiresAction({
      billingRequest: billingRequest,
      actionType: ActionType.SelectInstitution,
    })
  );
};

/**
 * returns true when
 * 1. errors has account_number error
 * 2. account_number error is of "invalid" type
 * 3. scheme is "becs_nz"
 * @param billingRequest
 * @param errors
 * @returns boolean
 */

export const hasInvalidAccountNumberErrorForBecsNZScheme = ({
  billingRequest,
  errors,
}: {
  billingRequest: BillingRequestResource | undefined;
  errors: NestDataObject<BillingRequestCollectBankAccountRequestBody>;
}) => {
  return Boolean(
    billingRequest?.mandate_request?.scheme === Scheme.BecsNz &&
      errors["account_number"] &&
      errors["account_number"].type === "invalid"
  );
};

export const isBankAuthorisationExpired = (
  bankAuthorisation: BankAuthorisationResource
) => {
  if (!bankAuthorisation.expires_at) return false;

  const currentTime = new Date().getTime();
  const expiresAt = new Date(bankAuthorisation.expires_at).getTime();
  return expiresAt < currentTime;
};

export const isBankAuthorisationRejected = (
  bankAuthorisation: BankAuthorisationResource
) => {
  return !!bankAuthorisation.failure_reason;
};

export const isPaymentAgreementEditable = (
  bankAuthorisation?: BankAuthorisationResource
) => {
  if (!bankAuthorisation) return true;
  if (isBankAuthorisationExpired(bankAuthorisation)) return true;

  return isBankAuthorisationRejected(bankAuthorisation);
};

/**
 * Checks whether we autocompleted the collect-bank-account step
 * as a part of bank authorisation process (at the moment supported only for VRP)
 *
 * @param billingRequest Billing Request object
 * @return true if paysvc didn't get bank details back after completing bank authorisation
 */
export const hasBankDetailsRetrievalFailed = (
  billingRequest?: BillingRequestResource
): Boolean => {
  if (!billingRequest) return false;

  const bankAuthorisationCompleted = Boolean(
    findAction(
      billingRequest,
      ActionType.BankAuthorisation,
      ActionStatus.Completed
    )
  );

  const collectBankAccountCompleted = Boolean(
    findAction(
      billingRequest,
      ActionType.CollectBankAccount,
      ActionStatus.Completed
    )
  );

  return (
    isVRPFlow(billingRequest) &&
    bankAuthorisationCompleted &&
    !collectBankAccountCompleted
  );
};

// Note: when updating any of the google form link, make sure to update
// the neccessary unit tests
export const getFeedbackLinks = (
  billingRequest: BillingRequestResource
): { [key: string]: string } => {
  const currency = getBillingRequestCurrency(billingRequest);
  switch (currency) {
    case Currency.Gbp:
      if (isVerifiedMandate(billingRequest)) {
        return {
          [Routes.BankSelect]: "https://forms.gle/SsHFDR2sJAyYBLQi8",
          [Routes.BankConnect]: "https://forms.gle/gdfeRwB9yBxkkVfk8",
          [Routes.BankConnectRequest]: "https://forms.gle/wN6EoDC39ncfFQCz8",
          [Routes.BankConfirm]: "https://forms.gle/U2TamWDybH827XU26",
          [Routes.Success]: "https://forms.gle/zkGxj6mcB9yxTpVv9",
        };
      }
      if (isMandateOnlyFlow(billingRequest)) {
        return {
          [Routes.CollectCustomerDetails]:
            "https://forms.gle/uVfWNk2sWMU42WkQ8",
          [Routes.CollectBankAccount]: "https://forms.gle/3DZxmn34qWjxfqAY9",
          [Routes.BankConfirm]: "https://forms.gle/Leg9rJ9Dc7dBGY5D8",
          [Routes.Success]: "https://forms.gle/2aaT33K1TjbqMJCb8",
        };
      }
      if (isOneOffPaymentFlow(billingRequest)) {
        return {
          [Routes.CollectCustomerDetails]:
            "https://forms.gle/nowo81SXCyDjoKJ29",
          [Routes.CollectBankAccount]: "https://forms.gle/nJTb5dsoGGr5ZJ4aA",
          [Routes.BankSelect]: "https://forms.gle/SsHFDR2sJAyYBLQi8",
          [Routes.BankConnect]: "https://forms.gle/gdfeRwB9yBxkkVfk8",
          [Routes.BankConfirm]: "https://forms.gle/5uZxXTjHfLSASNbL6",
          [Routes.Success]: "https://forms.gle/gAp3B62geb7nh4hD7",
        };
      }
      return {
        [Routes.CollectCustomerDetails]: "https://forms.gle/b4RVCJ93JR5ke23e8",
        [Routes.CollectBankAccount]: "https://forms.gle/dKcQdGDGugh98cFp7",
        [Routes.BankSelect]: "https://forms.gle/SsHFDR2sJAyYBLQi8",
        [Routes.BankConnect]: "https://forms.gle/gdfeRwB9yBxkkVfk8",
        [Routes.BankConfirm]: "https://forms.gle/B4MxN2Rbg69PShNH6",
        [Routes.Success]: "https://forms.gle/EZFSu12AsHJbP7ALA",
      };
    default:
      return {
        [Routes.CollectCustomerDetails]:
          "https://docs.google.com/forms/d/e/1FAIpQLSdBao3QwWba07crFvV1x0ICBadTArn8p7e7i6C7DLW5ovtZUQ/viewform",
        [Routes.CollectBankAccount]:
          "https://docs.google.com/forms/d/e/1FAIpQLSd_MglGyedi-AHrQH3PUnjsZGh0bBNS3BUpCJcwM2_wLvBfzw/viewform",
        [Routes.BankConfirm]:
          "https://docs.google.com/forms/d/e/1FAIpQLSfrOTA3Wp9Isz5DRhnCrTmK3hbAZ-534josQE5_HeLnN5jTww/viewform",
        [Routes.BankConnect]:
          "https://docs.google.com/forms/d/e/1FAIpQLSc8xi5FbskDFKVwq88BdD_s3bVW9StD19XUGTioWDdBonDBPQ/viewform",
      };
  }
};

export const getFeedBackLinkForPage = (
  page: string,
  billingRequest: BillingRequestResource
) => getFeedbackLinks(billingRequest)?.[page] ?? null;

export const isEligibleForFallback = (
  billingRequest?: BillingRequestResource,
  bankAuthorisation?: BankAuthorisationResource
) =>
  billingRequest?.fallback_enabled &&
  BankAuthFailureReasonsToShowFallback.includes(
    bankAuthorisation?.failure_reason as BankAuthFailureReasonsEnum
  );

export const isEligibleForSingleTabFlow = (
  runtimeMode: RuntimeMode,
  billingRequest?: BillingRequestResource
) =>
  runtimeMode !== RuntimeMode.Dropin &&
  billingRequest?.experimentation?.is_eligible_for_single_tab_experiments;

export const customerDetailsExist = ({
  billingRequest,
}: {
  billingRequest: BillingRequestResource;
}): boolean => {
  const customer = billingRequest.resources?.customer;
  if (!customer) {
    return false;
  }

  const customerNameExists =
    !!customer.company_name || !!customer.given_name || !!customer.family_name;

  const swedishIdentityExists =
    !!billingRequest.resources?.customer_billing_detail
      ?.swedish_identity_number;

  const emailExists = !!customer.email;

  return customerNameExists || swedishIdentityExists || emailExists;
};
